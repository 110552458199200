<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        href="/home"
      >
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          HEALTH PRO
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-end p-5 login-background"
        v-bind:style="{ backgroundImage: imgUrl }"
      >
        <div
          class="w-100 d-lg-flex  px-1 "
          align-v="end"
          style="background: rgba(255, 255, 255, 0.85);"
        >
          <b-row
            class="p-2"
            align-v="end"
          >
            <div>
              <b-img
                v-show="false"
                class="mx-2 mb-2"
                fluid
                style="height:30px;"
                :src="require('@/assets/images/pages/calendar-illustration.png')"
                alt="Login V2"
              />
            </div>
            <!-- <b-col>
              <h2 class="text-primary">
                A Comprehensive Intergrated Health Information Management System
              </h2>
            </b-col> -->
          </b-row>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div v-if="false">
            <b-img
              class="mx-0 mb-2"
              fluid
              style="height:45px;"
              src="https://kapkatet.health.go.ke/files/tiberbu_logo.png"
              alt="Login V2"
            />
          </div>
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Hi, Welcome! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="dark"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text
            v-show="false"
            class="text-center mt-2"
          >
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <b-card-text
            v-show="false"
            class="text-center mt-4"
          >
            <span>Take me back </span>
            <b-link href="/">
              <span>&nbsp;home </span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/moh.jpeg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            usr: this.userEmail,
            pwd: this.password,
          })
            .then(async response => {
              console.log(response)
              const ability = [{ action: 'manage', subject: 'all' }]
              const user = {}
              user.full_name = response.data.full_name
              user.email = this.userEmail
              localStorage.setItem('userData', JSON.stringify(user))
              this.$ability.update(ability)
              this.storeUserProfileFromCookies();
              const roles =  await this.getRoles();
              console.log(window.roles)

              this.$router.push({ name: 'account-switcher' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged. Now you can start to explore!',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.data.message)
                })
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login Error',
                  icon: 'XOctagonIcon',
                  variant: 'danger',
                  text: 'Invalid credentials please try again',
                },
              })
            })
        }
      })
    },
    async loginx() {
      try {
        const response = await fetch('http://127.0.0.1:8001/api/method/login', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            usr: this.userEmail,
            pwd: this.password,
          }),
        })

        // Assuming the backend returns cookies after successful login
        const cookies = response.headers.get('set-cookie')
        console.log('cookies')
        console.log(cookies)
        console.log(response)
        // Store the cookies for future requests
        // this.$cookies.set("session", cookies) // You will need to install a cookies plugin
      } catch (error) {
        // Handle login error
        console.error('Login failed', error)
      }
    },
    storeUserProfileFromCookies() {
      // Retrieve user data from cookies
      const userData = {
        user_id: this.getCookie('user_id'),
        sid: this.getCookie('sid'),
        full_name: this.getCookie('full_name'),
        system_user: this.getCookie('system_user'),
      };
      let existingUserProfiles = JSON.parse(localStorage.getItem('userProfiles')) || [];
      existingUserProfiles = existingUserProfiles.filter(x => x.user_id != userData.user_id) 
      existingUserProfiles.push(userData);
      // Store user profile in local storage
      localStorage.setItem('userProfiles', JSON.stringify(existingUserProfiles));
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';

  .login-background{
    background-image: url('/img/register-v2.0a9e487c.svg');
      background-repeat: no-repeat;
      background-size: cover;
      
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
  }
  .glass-background{
    /* From https://css.glass */
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  }
  </style>
